/* App.css */
body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #f0f8ff;
  overflow: hidden;
  position: relative;
}

.snowflake {
  position: absolute;
  top: -10px;
  color: #ffffff;
  font-size: 1.5em;
  opacity: 0.8;
  animation: fall linear infinite;
}

.snowflake:nth-child(5n) {
  color: #b3e5fc; /* Variation de couleur pour les flocons */
}

.leaf {
  position: absolute;
  top: -10px;
  color: #00a000;
  font-size: 1.5em;
  opacity: 0.9;
  animation: fall linear infinite;
}

@keyframes fall {
  0% { transform: translateY(-10px) rotate(0deg); opacity: 1; }
  100% { transform: translateY(100vh) rotate(360deg); opacity: 0; }
}

.App {
  text-align: center;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 12px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 300px;
  z-index: 10; /* Assure que l'interface est au-dessus des flocons */
}

h1 {
  color: #d32f2f;
  font-size: 2em;
  margin-bottom: 20px;
}

form {
  display: flex;
  flex-direction: column;
}

input[type="text"] {
  padding: 10px;
  font-size: 1em;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 10px;
  width: 100%;
  box-sizing: border-box;
}

button {
  background-color: #d32f2f;
  color: white;
  padding: 10px;
  font-size: 1em;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
  transition: background 0.3s;
}

button:hover {
  background-color: #b71c1c;
}
